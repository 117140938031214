import React, { useEffect, useState } from 'react';
import { formatDuration, Locale } from 'date-fns';
import styles from './bfTitle.module.scss';
import { useTranslation } from 'next-i18next';
import { enGB, ja, ko, ru, uk, zhCN } from 'date-fns/locale';
import { differenceInSeconds, secondsToDuration } from '@/lib/helpers';
import TimeUnit from '@/pageComponents/BlackFridayComponents/BFTitle/TimeUnit';

interface CountdownTimerProps {
    eventDate: Date;
}

const locales: Record<string, Locale> = {
    en: enGB,
    ru,
    uk,
    ko,
    ja,
    zh: zhCN,
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({ eventDate }) => {
    const { t, i18n } = useTranslation();
    const getUnits = (eventDate: Date): { label?: string; value: number | string }[] => {
        const formattedDuration = formatDuration(
            secondsToDuration(differenceInSeconds(new Date(), eventDate)),

            {
                locale: locales[i18n.language],
                zero: true,
                format: ['days', 'hours', 'minutes', 'seconds'],
            },
        );
        const splitDuration = formattedDuration.split(' ');
        const resultUnits: { label?: string; value: number | string }[] = [];

        splitDuration.forEach((item, index) => {
            if (i18n.language === 'ja' || i18n.language === 'ko') {
                const value = parseInt(item, 10);
                const label = item.replace(value.toString(), '');
                return resultUnits.push({ value, label });
            }
            if (!isNaN(+item)) {
                return resultUnits.push({ label: splitDuration[index + 1], value: +item });
            }
        });

        return resultUnits;
    };
    const [duration, setDuration] = useState(getUnits(eventDate));

    useEffect(() => {
        const timer = setInterval(() => {
            setDuration(getUnits(eventDate));
        }, 1000);

        return () => clearInterval(timer);
    }, [eventDate, i18n.language]);

    return (
        <div className={styles.countDownContainer}>
            {duration.map((item) => (
                <TimeUnit key={item.label} value={item.value} label={item.label} />
            ))}
        </div>
    );
};

export default CountdownTimer;
