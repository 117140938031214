import React from 'react';
import dynamic from 'next/dynamic';
import Section from '@/components/Section';
import styles from './testimonials.module.scss';
import { Button } from '@/components/Buttons';
import { getRefLink } from '@/lib/helpers';
import Link from '@/components/Link';
import { ROUTES } from '@/lib/constants.mjs';
/* TODO remove test data after twitter integration */
import { Tweet } from '@/types/generalTypes';
import { useTranslation } from 'next-i18next';

const FeedbackCard = dynamic(() => import('@/components/FeedbackCard'), { ssr: false });

interface TestimonialsProps {
    tweets: Tweet[];
}

const Testimonials: React.FC<TestimonialsProps> = ({ tweets = [] }) => {
    const { t } = useTranslation('common');

    const renderTweets = tweets.slice(0, 6);

    return (
        <Section data-sal="fade" className="fillBgSkewBoth" innerClassName="unSkew">
            <div className={`marginTop marginBottom ${styles.tradersSection}`}>
                <div className={styles.tradersTitleWrapper}>
                    <h2 className={`as-h2 ${styles.tradersTitle}`}>
                        {t('testimonials_section.title' as any)}
                    </h2>
                    <Button
                        size={'large'}
                        mobileSize={'full'}
                        color={'light'}
                        to={getRefLink()}
                        blank={false}
                    >
                        {t('get_started_button_label' as any)}
                    </Button>
                </div>
                <div className={styles.tradersMasonryContainer}>
                    {renderTweets.map((item) => (
                        <div
                            className={styles.tradersMasonryCell}
                            key={`${item.username}_${item.name}_${item.id}`}
                        >
                            <FeedbackCard
                                name={item.name}
                                username={item.username}
                                profileImage={item.profileImage}
                                text={item.text}
                                image={null}
                                link={item.link}
                                created={item.created}
                            />
                        </div>
                    ))}
                </div>
                <div className={styles.tradersLink}>
                    <Link
                        color="light"
                        label={t('testimonials_section.see_more_label' as any) as string}
                        to={ROUTES.TESTIMONIALS}
                    />
                </div>
            </div>
        </Section>
    );
};

export default Testimonials;
