import React, { PropsWithChildren } from 'react';

interface MainSectionProps {
    className?: string;
    gap?: string;
    marginTop?: boolean;
    marginBottom?: boolean;
    removeMarginBottomOnMobile?: boolean;
    removeMarginTopOnMobile?: boolean;
    mobileFlexDirection?: 'column' | 'column-reverse';
    defaultDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
}

export const MainSection: React.FC<PropsWithChildren<MainSectionProps>> = ({
    mobileFlexDirection = 'column-reverse',
    defaultDirection = 'row',
    children,
    className = '',
    gap = 'unset',
    marginTop = false,
    marginBottom = false,
    removeMarginTopOnMobile = false,
    removeMarginBottomOnMobile = false,
}) => (
    <div
        className={`content-section ${
            defaultDirection ? `content-section__${defaultDirection}` : ''
        } ${
            mobileFlexDirection === 'column' ? 'content-section--mobile-column' : ''
        } ${className} ${marginBottom ? 'marginBottom' : ''} ${marginTop ? 'marginTop' : ''} ${
            removeMarginBottomOnMobile ? 'removeMarginBottomOnMobile' : ''
        } ${removeMarginTopOnMobile ? 'removeMarginTopOnMobile' : ''}`}
        style={{ gap }}
    >
        {children}
    </div>
);

export const MainSubSection: React.FC<PropsWithChildren<{ className?: string; gap?: string }>> = ({
    children,
    className = '',
    gap = 'unset',
}) => (
    <div className={`content-subsection ${className}`} style={{ gap }}>
        {children}
    </div>
);

interface LightSectionProps extends MainSectionProps {
    reversed?: boolean;
}

export const LightSection: React.FC<PropsWithChildren<LightSectionProps>> = ({
    reversed = false,
    children,
    className = '',
    gap = 'unset',
    marginTop = false,
    marginBottom = false,
    mobileFlexDirection,
}) => (
    <div
        className={`content-section--light ${
            reversed ? 'content-section--light__reverse' : ''
        } ${className} ${marginBottom ? 'marginBottom' : ''} ${marginTop ? 'marginTop' : ''} ${
            mobileFlexDirection === 'column-reverse'
                ? 'content-section--light__mobile-column-reverse'
                : ''
        }`}
        style={{ gap }}
    >
        {children}
    </div>
);

export const LightSubSection: React.FC<
    PropsWithChildren<{ className?: string; gap?: string; reversed?: boolean }>
> = ({ children, className = '', gap = 'unset', reversed }) => (
    <div
        className={`content-subsection--light ${
            reversed ? 'content-subsection--light__reversed' : ''
        } ${className}`}
        style={{ gap }}
    >
        {children}
    </div>
);

export const LightSectionTitle: React.FC<PropsWithChildren<{ className?: string }>> = ({
    children,
    className = '',
}) => {
    return <h2 className={`lightSectionTitle ${className}`}>{children}</h2>;
};

export const ImageWrapper: React.FC<
    PropsWithChildren<{ className?: string; withImageBackground?: boolean }>
> = ({ children, className = '', withImageBackground }) => {
    return (
        <div
            className={`imageWrapper ${
                withImageBackground ? 'imageWrapper-withBG' : ''
            } ${className}`}
        >
            {children}
        </div>
    );
};

export const LightSectionTextWrapper: React.FC<PropsWithChildren<{ className?: string }>> = ({
    children,
    className = '',
}) => {
    return <div className={`lightSectionTextWrapper ${className}`}>{children}</div>;
};

export const LightSectionGridWrapper: React.FC<PropsWithChildren<{ className?: string }>> = ({
    children,
    className = '',
}) => {
    return <div className={`lightSectionGridWrapper ${className}`}>{children}</div>;
};

export const LightSectionButtonContainer: React.FC<PropsWithChildren<{ className?: string }>> = ({
    children,
    className = '',
}) => {
    return <div className={`lightSectionButtonContainer ${className}`}>{children}</div>;
};
