import React from 'react';
import styles from './bfTitle.module.scss';

interface TimeUnitProps {
    value: number | string;
    label?: string;
}

const TimeUnit: React.FC<TimeUnitProps> = ({ value, label }) => {
    return (
        <div className={styles.timeUnit}>
            <div className={styles.timeValue}>{value}</div>
            {label && <div className={styles.timeLabel}>{label.slice(0, 4)}</div>}
        </div>
    );
};

export default TimeUnit;
