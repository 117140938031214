import React from 'react';
import Head from 'next/head';
import { FC } from 'react';
import { availableLanguages, HOST } from '@/lib/constants.mjs';
import { ArticleSeo, QA } from '@/types/generalTypes';
import { ArticleJsonLd, FAQPageJsonLd } from 'next-seo';

interface SeoProps {
    title: string;
    description: string;
    image: string;
    route: string;
    ogType?: string;
    qa?: QA[];
    article?: ArticleSeo;
    ogData?: any;
    twitterData?: any;
    imageWidth?: string | number;
    imageHeight?: string | number;
}

const Seo: FC<SeoProps> = ({
    title,
    description,
    image,
    route,
    ogType = 'website',
    ogData,
    article,
    twitterData,
    qa,
    imageWidth,
    imageHeight,
}) => {
    const ogImage = ogData?.image?.url || image;
    const twitterImage = twitterData?.image?.url || image;
    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={`${HOST}${route}`} />
            <meta property="og:image:width" content={`${imageWidth || 1200}`} />
            <meta property="og:image:height" content={`${imageHeight || 630} `} />
            <meta property="og:type" content={ogType} />
            <meta property="og:title" content={ogData?.title || title} />
            <meta property="og:description" content={ogData?.description || description} />
            <meta property="og:url" content={`${HOST}${route}`} />
            <meta property="og:image:alt" content={description} />
            <meta property="og:description" content={description} />
            <meta name="twitter:title" content={twitterData?.title || title} />
            <meta name="twitter:description" content={twitterData?.description || description} />
            {(Object.keys(availableLanguages) as string[]).map((lang) => (
                <React.Fragment key={lang}>
                    {lang === 'en' ? (
                        <link
                            rel="alternate"
                            key={`default_${lang}`}
                            hrefLang={lang}
                            href={`${HOST}${route}`}
                        />
                    ) : (
                        <link
                            key={lang}
                            rel="alternate"
                            hrefLang={lang}
                            href={`${HOST}/${lang}${route}`}
                        />
                    )}
                </React.Fragment>
            ))}
            <meta
                name="twitter:image"
                content={twitterImage.includes('https') ? twitterImage : `${HOST}${twitterImage}`}
            />
            <meta
                property="og:image"
                content={ogImage.includes('https') ? ogImage : `${HOST}${ogImage}`}
            />
            {qa && qa.length > 0 && <FAQPageJsonLd mainEntity={qa} />}
            {article && Object.keys(article).length > 0 && (
                <ArticleJsonLd
                    authorName={article?.authorName || 'CoinMarketMan'}
                    type={article.type}
                    dateModified={article.dateModified}
                    url={`${HOST}/${route}`}
                    images={article.images}
                    datePublished={article.datePublished}
                    description={article.description}
                    publisherName={'CoinMarketMan'}
                    publisherLogo={''}
                    title={article.title}
                />
            )}
        </Head>
    );
};

export default Seo;
