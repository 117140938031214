import React from 'react';
import styles from './bfTitle.module.scss';
import { Trans, useTranslation } from 'next-i18next';
import { pressStart2P } from '@/lib/font';
import CountdownTimer from '@/pageComponents/BlackFridayComponents/BFTitle/CountdownTimer';

const BfTitle = () => {
    const { t, i18n } = useTranslation('black-friday');
    return (
        <div className={styles.titleContainer}>
            <p className={styles.subtitle}>{t('bf_top_section_subtitle')}</p>
            <h1
                style={{
                    textAlign:
                        i18n.language === 'uk' || i18n.language === 'ru' ? 'center' : 'justify',
                }}
                className={`as-h1 ${styles.title}  ${pressStart2P.className}`}
            >
                <Trans
                    t={t}
                    i18nKey={'bf_top_section_title'}
                    components={{ span: <span style={{ color: 'var(--warning-main)' }} /> }}
                    defaults={'OUR BIGGEST SALE EVER WITH <span>CMM BLACK FRIDAY</span>'}
                />
            </h1>
            <div className={styles.countDownWrapper}>
                <p className={styles.countDownTitle}>{t('bf_top_section_countdown_title')}</p>
                <CountdownTimer eventDate={new Date('11-29-2024')} />
            </div>
        </div>
    );
};

export default BfTitle;
